@import 'styles/base/vars.scss';
@import 'styles/base/global.scss';
@import 'styles/base/typography.scss';
@import 'styles/base/form.scss';
@import '../src/components/input/input.scss';

.app{
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
  padding: 0 var(--space-medium);
  &__inputs{
    display: grid;
    gap:var(--space-medium);
    margin: var(--space-xlarge) 0;
    @media(min-width: 640px){
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__preview{
    border-radius: 5px;
    position: relative;
    min-height: calc( var(--circle-size) * 2);
  }
  &__previewobject{
    width:var(--circle-size);
    height:var(--circle-size);
    border-radius: 50%;
    background: var(--color-object-body);
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
}
