
html{
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: geometricPrecision;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
  background: var(--color-page-bg);
  color:var(--color-text);
}

*, *::before, *::after {
  box-sizing: border-box;
}

body{
  font-size:.9375rem;
  line-height: 1.33;
  font-optical-sizing: auto;
  font-style: normal;
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
